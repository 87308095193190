import React, { Fragment } from 'react'
import {
  Container,
  DesktopNavigation,
  Logo,
  MobileContact,
  DItem,
  Left,
  Right,
  MItem,
  MItemImg,
  MText,
  MobileNav,
  LogoImg,
  MobileNavi,
} from './style.js'
import { Button } from '../Button/Button.js'
import { Link } from 'gatsby'
import { Popover, Transition } from '@headlessui/react'
import bar from '../../images/bars.svg'
import xmark from '../../images/xmark.svg'
import logo from '../../images/logo-color.svg'

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'UI/UX', href: '/ui-ux' },
  { name: 'Apps', href: '/web-applications/' },
  { name: 'Ecommerce', href: '/ecommerce/' },
  { name: 'Blogs', href: '/blogs/' },
  { name: 'Landing Pages', href: '/landing-pages/' },
]

const Navbar = () => {
  return (
    <Container>
      <DesktopNavigation>
        <MobileNavi>
          <Popover>
            {({ open }) => (
              <>
                <Popover.Button style={{ backgroundColor: 'transparent' , zIndex: '1005' }}>
                  {open ? (
                    <img
                      style={{ height: '25px', width: '25px', opacity: '0.7' }}
                      aria-hidden="true"
                      src={xmark}
                    />
                  ) : (
                    <img
                      style={{ height: '25px', width: '25px', opacity: '0.7', zIndex: '1005' }}
                      aria-hidden="true"
                      src={bar}
                    />
                  )}
                </Popover.Button>
                <Popover.Overlay
                  style={{
                    backgroundColor: 'rgb(203 213 225 / 0.7)',
                    opacity: '1',
                    position: 'fixed',
                    zIndex: '1000',
                    top: '0',
                    left: '0',
                    right: '0',
                    bottom: '0',
                  }}
                />
                <Popover.Panel
                  style={{ position: 'absolute', zIndex: '1001', width: '95%' , backgroundColor: '#444', left: '10px', borderRadius: '10px', padding: '10px 0'}}
                >
                  <div>
                    {navigation.map((item) => (
                      <Popover.Button
                        key={item.name}
                        as={Link}
                        to={item.href}
                        aria-current="page"
                        style={{display: 'block', padding: '10px', color: '#fff', fontFamily: 'Averta', fontSize: '1.2rem', fontWeight: '600', textDecoration: 'none', marginLeft: '10px'}}
                      >
                        {item.name}
                      </Popover.Button>
                    ))}
                  </div>
                </Popover.Panel>
              </>
            )}
          </Popover>
        </MobileNavi>
        <Logo>
          <Link to="/">
            <LogoImg src={logo} alt="logo" />
          </Link>
        </Logo>
        <MobileContact>
          <Link to="/contact/">
            <Button>Contact</Button>
          </Link>
        </MobileContact>
        <Left>
          <DItem>
            <Link activeStyle={{ color: '#000', fontFamily: 'Averta' }} to="/">
              Home
            </Link>
          </DItem>
          <DItem>
            <Link
              activeStyle={{ color: '#000', fontFamily: 'Averta' }}
              to="/blogs"
            >
              Blogs
            </Link>
          </DItem>
          <DItem>
            <Link
              activeStyle={{ color: '#000', fontFamily: 'Averta' }}
              to="/landing-pages/"
            >
              Landing Pages
            </Link>
          </DItem>
          <DItem>
            <Link
              activeStyle={{ color: '#000', fontFamily: 'Averta' }}
              to="/ui-ux/"
            >
              UI/UX
            </Link>
          </DItem>
          <DItem>
            <Link
              activeStyle={{ color: '#000', fontFamily: 'Averta' }}
              to="/web-applications/"
            >
              Applications
            </Link>
          </DItem>
          <DItem>
            <Link
              activeStyle={{ color: '#000', fontFamily: 'Averta' }}
              to="/ecommerce/"
            >
              Ecommerce
            </Link>
          </DItem>
        </Left>
        <Right>
          <Link to="/contact/">
            <Button>Contact</Button>
          </Link>
        </Right>
      </DesktopNavigation>
    </Container>
  )
}

export default Navbar
